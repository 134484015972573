<template>
  <quick-preview :is-preview="show" @input="closePreview">
    <template v-slot:content-en>
      <hero-banner-card
        :src="enContentObject.thumbnail"
        label="Do not show this page again."
      ></hero-banner-card>
    </template>
    <template v-slot:content-th>
      <hero-banner-card
        :src="thContentObject.thumbnail"
        label="ไม่ต้องแสดงหน้านี้อีก"
      ></hero-banner-card>
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/QuickPreview'
import HeroBannerCard from './HeroBannerCard'

export default {
  name: 'Preview',
  components: {
    QuickPreview,
    HeroBannerCard
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    dataObject: {type: Object}
  },
  data() {
    return {
      activeTab: 'th'
    }
  },
  computed: {
    enContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'en'
      })[0]
    },
    thContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'th'
      })[0]
    }
  }
}
</script>
