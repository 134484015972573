<template>
  <v-container class="edit-container px-10" fluid>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <v-popup-loading :isLoading="isLoading" />
    <alert-error
      :show="isError"
      :msg="msgError"
      :close="closeAlert"
    ></alert-error>
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Hero Banner
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="isDisabledPreview()"
            :on-remove="showDeletePopup"
            :on-preview="showPreview"
            :on-cancel="showCancelPopup"
            :on-save="save"
          />
        </v-row>
      </v-col>
    </v-row>

    <preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :data-object="heroBannerObject"/>

    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Hero Banner Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="heroBannerObject.status"
            :disabled="disabledEdit || disableEmptyBranch || isExpired"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="6">
              <p
                class="body-regular warning-content mb-6"
                t-data="warning-content"
              >
                All fields are required.
              </p>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch"
                :isDisabled="
                  disabledEdit || isUnpublished || isPublished || isExpired
                "
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                :isEditMode="isEditMode"
                v-if="!isLoading"
                @change="changeBranch"
              ></single-selected-branch>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Banner Type
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Type"
                v-model="heroBannerObject.type"
                :disabled="
                  disabledEdit ||
                    disableEmptyBranch ||
                    isUnpublished ||
                    isPublished ||
                    isExpired
                "
                :menu-props="{bottom: true, offsetY: true}"
                :items="typeItems"
                item-text="text"
                item-value="value"
                :rules="bannerTypeRules"
                @change="changeType"
              ></v-select>
            </v-col>
          </v-row>
          <h6 class="mt-6 mb-3 card-title" t-data="info-title">
            Display Page
          </h6>
          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Appear On
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select a display page"
                v-model="heroBannerObject.appear_on_page_type"
                :menu-props="{bottom: true, offsetY: true}"
                :items="pageItems"
                item-text="text"
                item-value="value"
                :disabled="
                  pageItems.length <= 0 ||
                    disabledEdit ||
                    disableEmptyBranch ||
                    isExpired
                "
                :rules="appearOnRules"
              ></v-select>
            </v-col>
          </v-row>
          <template v-if="heroBannerObject.type === typeEnum.INTERACTION">
            <h6 class="mt-6 mb-3 card-title" t-data="info-title">
              Link To
            </h6>
            <v-row
              align="start"
              justify="start"
              class="mt-0 px-2"
              t-data="interaction-container"
            >
              <v-col cols="12" t-data="info-input">
                <v-radio-group
                  v-model="heroBannerObject.link_to"
                  class="mt-0"
                  row
                  hide-details
                  @change="changeLinkTo"
                >
                  <v-radio
                    v-for="item in linkToItems"
                    :key="item.value"
                    :value="item.value"
                    :label="item.text"
                    :disabled="
                      disabledEdit ||
                        disableEmptyBranch ||
                        isUnpublished ||
                        isPublished ||
                        isExpired
                    "
                    color="secondary"
                    >{{ item }}</v-radio
                  >
                </v-radio-group>
              </v-col>
              <template
                v-if="heroBannerObject.link_to === linkToEnum.LINK_WITHIN_APP"
                ><v-col cols="6" t-data="info-input" class="mt-2">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Link to Content
                  </p>
                  <v-text-field
                    outlined
                    required
                    dense
                    :append-icon="
                      sectionInfo.length > 0 ? undefined : 'mdi-chevron-right'
                    "
                    class="can-disable-selector"
                    placeholder="Select a content"
                    :value="sectionInfo"
                    :disabled="disabledEdit || disableEmptyBranch || isExpired"
                    @click="clickLinkToContent"
                    @click:clear="clearLinkContent"
                    readonly
                    clearable
                    :rules="showSelectContent ? [] : linkToContentRules"
                  ></v-text-field>
                  <content-dialog
                    :key="componentKey"
                    :show="showSelectContent"
                    :is-clear="isClearContentDialog"
                    @close="showSelectContent = false"
                    @resetDatetime="resetPublishDateTime"
                    @clear="isClearContentDialog = true"
                  ></content-dialog>
                </v-col>
                <v-col cols="3" t-data="info-input">
                  <p
                    class="edit-field-label sub-body-bold mb-4"
                    t-data="info-label"
                  >
                    Content Title TH
                  </p>
                  <span :class="['body-regular', linkContentClass]">{{
                    linkContentTitleTh
                  }}</span>
                </v-col>
                <v-col cols="3" t-data="info-input">
                  <p
                    class="edit-field-label sub-body-bold mb-4"
                    t-data="info-label"
                  >
                    Content Title EN
                  </p>
                  <span :class="['body-regular', linkContentClass]">{{
                    linkContentTitleEn
                  }}</span>
                </v-col>
              </template>
              <template v-else>
                <v-col
                  t-data="website-warning-alert"
                  cols="12"
                  class="sub-body-bold secondary-text--text mt-2 mb-6 d-flex align-content-center"
                >
                  <v-icon size="13" class="mr-1" left light>
                    mdi-information
                  </v-icon>
                  Link to external source (URL)
                </v-col>
                <v-col cols="6" t-data="info-input" class="mt-2">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Browser Type
                  </p>
                  <v-select
                    outlined
                    dense
                    class="can-disable-selector"
                    placeholder="Select Display Type"
                    v-model="heroBannerObject.browser_type"
                    :disabled="disabledEdit || disableEmptyBranch || isExpired"
                    :menu-props="{bottom: true, offsetY: true}"
                    :items="browserTypeItems"
                    item-text="text"
                    item-value="value"
                    :rules="browserTypeRules"
                    @change="changeBrowserType"
                  ></v-select>
                </v-col>
                <v-col cols="6" t-data="info-input" class="mt-2">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    URL
                  </p>
                  <v-text-field
                    class="can-disable-selector"
                    t-data="info-value"
                    placeholder="Enter your external URL here. (ex. https://www.google.co.th)"
                    outlined
                    required
                    dense
                    v-model="heroBannerObject.route_value"
                    :rules="urlRules"
                    :disabled="disabledEdit || disableEmptyBranch || isExpired"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </template>
          <h6 class="mt-6 mb-3 card-title" t-data="publish-datetime-title">
            Publish Date And Time
          </h6>
          <v-form
            ref="datetimeForm"
            t-data="publish-datetime-form"
            lazy-validation
          >
            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Publish Start Date
                </p>
                <custom-date-picker
                  placeholder="Select Start Date"
                  v-model="heroBannerObject.start_date"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      isPublished ||
                      isExpired
                  "
                  :rule="publishStartDateRules"
                  @input="startDateChange"
                  outlined
                  required
                ></custom-date-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Start Time
                </p>
                <time-picker
                  placeholder="Select Start Time"
                  v-model="heroBannerObject.start_time"
                  @input="startTimeChange"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      isPublished ||
                      isExpired
                  "
                  :rule="startTimeRules"
                  outlined
                  required
                ></time-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Publish End Date
                </p>
                <custom-date-picker
                  placeholder="Select End Date"
                  v-model="heroBannerObject.end_date"
                  @input="endDateChange"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      disableHasExpiredDate ||
                      isExpired
                  "
                  :rule="publishEndDateRules"
                  :allowedDates="allowedDates"
                  outlined
                  required
                ></custom-date-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  End Time
                </p>
                <time-picker
                  placeholder="Select End Time"
                  v-model="heroBannerObject.end_time"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      disableHasExpiredDate ||
                      isExpired
                  "
                  :rule="endTimeRules"
                  outlined
                  required
                ></time-picker>
              </v-col>
            </v-row>
          </v-form>

          <v-expansion-panels :value="0" class="mt-6 mb-4">
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                @click="checkThaiContentExpand"
                disable-icon-rotate
                t-data="thai-info-header"
              >
                <h6 class="mb-0 card-title">
                  Thai Content
                </h6>
                <template v-slot:actions>
                  <v-icon color="primary">
                    {{ isThaiContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content t-data="thai-info-content">
                <content-by-lang
                  lang="th"
                  :title="heroBannerObject.contents[0].title"
                  :url="heroBannerObject.contents[0].thumbnail"
                  :disabledEdit="
                    disabledEdit || disableEmptyBranch || isExpired
                  "
                  :setImage="setImage"
                  :setImageError="setImageError"
                  :isImageError="isImageThError"
                  :imageErrorMessage="imageThErrorMessage"
                  :required-title="true"
                  :required-image="true"
                  :show-required-icon="false"
                  @changeUrl="changeUrlTh"
                  @changeTitle="changeTitleTh"
                  @removeImage="removeImageTh"
                ></content-by-lang>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels :value="0" class="mb-4">
            <v-expansion-panel outlined>
              <v-expansion-panel-header
                @click="checkEngContentExpand"
                disable-icon-rotate
                t-data="eng-info-header"
              >
                <h6 class="mb-0 card-title">
                  English Content
                </h6>
                <v-checkbox
                  v-model="duplicateContent"
                  :disabled="disabledEdit || disableEmptyBranch || isExpired"
                  hide-details
                  label="Duplicate Thai content"
                  color="secondary"
                  class="check-box-text body-regular mt-0 pt-0"
                  @click="duplicateThaiContent"
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <template v-slot:actions>
                  <v-icon color="primary">
                    {{ isEngContentExpand ? '$minus' : '$plus' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content t-data="eng-info-content">
                <content-by-lang
                  lang="en"
                  :disabledEdit="
                    disabledEdit || disableEmptyBranch || isExpired
                  "
                  :title="heroBannerObject.contents[1].title"
                  :url="heroBannerObject.contents[1].thumbnail"
                  :setImage="setImage"
                  :setImageError="setImageError"
                  :isImageError="isImageEnError"
                  :imageErrorMessage="imageEnErrorMessage"
                  :required-title="true"
                  :required-image="true"
                  :show-required-icon="false"
                  @changeTitle="changeTitleEn"
                  @removeImage="removeImageEn"
                ></content-by-lang>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row v-if="isEditMode && updatedBy" t-data="update-detail">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Updated by
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                disabled
                v-model="updatedBy"
              ></v-text-field>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Updated Date/Time
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                required
                dense
                disabled
                v-model="updatedAt"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <created-detail v-if="isEditMode" :data-object="heroBannerObject" />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="isDisabledPreview()"
        :on-remove="showDeletePopup"
        :on-preview="showPreview"
        :on-cancel="showCancelPopup"
        :on-save="save"
      />
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import {mapActions, mapState} from 'vuex'

import AlertError from '@/components/AlertError'
import ContentByLang from '@/components/ContentByLang'
import ContentDialog from '@/views/hero-banner/ContentDialog'
import CreatedDetail from '@/components/CreatedDetail'
import CustomDatePicker from '@/components/DatePickerWithMin'
import EditButtons from '@/components/EditButtons'
import PopupLoading from '@/components/PopupLoading'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import SnackBar from '@/components/SnackBar'
import TimePicker from '@/components/TimePicker'
import {
  convertDate,
  hasPermissionsByOrgs,
  isUrl,
  snackbarFailContent,
  snackbarSuccessContent
} from '@/helper/helper'
import {
  appearOnPageTypeEnum,
  appearOnPageTypeText,
  browserTypeEnum,
  browserTypeText,
  linkToEnum,
  linkToText,
  typeEnum,
  typeText
} from '@/constants/hero-banner'
import {actionEnum} from '@/constants/constants'
import AlertConfirm from '@/components/AlertConfirm'
import {timeMoreThanCurrentRule, timeMoreThanStartRule} from '@/helper/rule'
import {sectionsWithExpired} from '@/constants/hero-banner'
import Preview from '@/components/hero-banner/Preview'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-popup-loading': PopupLoading,
    AlertError,
    ContentByLang,
    ContentDialog,
    CreatedDetail,
    CustomDatePicker,
    EditButtons,
    Preview,
    SingleSelectedBranch,
    SnackBar,
    TimePicker,
  },
  data() {
    return {
      isEngContentExpand: true,
      isThaiContentExpand: true,
      duplicateContent: false,
      isSuccess: false,
      isError: false,
      isEditMode: false,
      isCancel: false,
      isPreview: false,
      msgError: '',
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'created',
      pageName: 'hero banner',
      subTitle:
        'Are you sure you want to remove this Hero banner? This action will remove content in both Thai and English.',
      showSelectContent: false,
      linkToEnum: linkToEnum,
      typeEnum: typeEnum,
      isClearContentDialog: false,
      componentKey: 0,
      isBannerTypeError: false,
      isAppearOnError: false,
      isLinkToContentError: false,
      isBrowserTypeError: false,
      isUrlError: false,
      isPublishStartDateError: false,
      isStartTimeError: false,
      isPublishEndDateError: false,
      isEndTimeError: false,
      bannerTypeRules: [
        v => {
          if (v) {
            this.isBannerTypeError = false
            return true
          }
          this.isBannerTypeError = true
          return 'Type is required'
        }
      ],
      appearOnRules: [
        v => {
          if (v) {
            this.isAppearOnError = false
            return true
          }
          this.isAppearOnError = true
          return 'Appear on is required'
        }
      ],
      linkToContentRules: [
        v => {
          if (v) {
            this.isLinkToContentError = false
            return true
          }
          this.isLinkToContentError = true
          return 'Link to Content is required'
        }
      ],
      browserTypeRules: [
        v => {
          if (v) {
            this.isBrowserTypeError = false
            return true
          }
          this.isBrowserTypeError = true
          return 'Browser Type is required'
        }
      ],
      urlRules: [
        v => {
          if (v) {
            this.isUrlError = false
            return true
          }
          this.isUrlError = true
          return 'URL is required'
        }
      ],
      publishStartDateRules: [
        v => {
          if (v) {
            this.isPublishStartDateError = false
            return true
          }
          this.isPublishStartDateError = true
          return 'Date is required'
        },
        v => {
          const result = this.startDateRule(v)
          if (typeof result === 'string') {
            this.isPublishStartDateError = true
            return result
          }
          return result
        }
      ],
      startTimeRules: [
        v => {
          if (v) {
            this.isStartTimeError = false
            return true
          }
          this.isStartTimeError = true
          return 'Time is required'
        },
        v => {
          const result = this.startTimeMoreThanCurrentRule(v)
          if (typeof result === 'string') {
            this.isStartTimeError = true
            return result
          }
          return result
        },
        v => {
          const result = this.startTimeMoreThanEndTimeRule(v)
          if (typeof result === 'string') {
            this.isStartTimeError = true
            return result
          }
          return result
        }
      ],
      publishEndDateRules: [
        v => {
          if (v) {
            this.isPublishEndDateError = false
            return true
          }
          this.isPublishEndDateError = true
          return 'Date is required'
        }
      ],
      endTimeRules: [
        v => {
          if (v) {
            this.isEndTimeError = false
            return true
          }
          this.isEndTimeError = true
          return 'Time is required'
        },
        v => {
          const result = this.endTimeRule(v)
          if (typeof result === 'string') {
            this.isEndTimeError = true
            return result
          }
          return result
        }
      ]
    }
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(!!this.$route.params.id)
    await this.fetchSections()
    if (this.isEditMode) {
      await this.setHeroBannerObject(this.$route.params.id)
    }
    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditHeroBanner', [
        this.heroBannerObject.org_codename
      ])
    await this.setOrgInfo(isViewOnly)
  },
  beforeMount() {
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  computed: {
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteHeroBanner', [
        this.heroBannerObject.org_codename
      ])
    },
    disableEmptyBranch() {
      return this.heroBannerObject.org_codename === null
    },
    disableHasExpiredDate() {
      return (
        this.heroBannerObject.end_date !== '' &&
        this.heroBannerObject.end_time !== '' &&
        sectionsWithExpired.includes(this.heroBannerObject.section.type)
      )
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', disabled: false, to: '/'},
        {
          text: 'Hero Banner',
          disabled: false,
          href: '/hero-banners'
        },
        {text: `${this.textMode} Hero Banner`, disabled: true, links: false}
      ]
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, 'created')
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.heroBannerObject.org_codename !== null) {
        selectedBranch = this.heroBannerObject.org_codename
      }
      return selectedBranch
    },
    typeItems() {
      const items = []
      Object.keys(typeEnum).forEach(key =>
        items.push({
          text: typeText[key],
          value: key
        })
      )
      return items
    },
    browserTypeItems() {
      const items = []
      Object.keys(browserTypeEnum).forEach(key =>
        items.push({
          text: browserTypeText[browserTypeEnum[key]],
          value: browserTypeEnum[key]
        })
      )
      return items
    },
    pageItems() {
      return Object.keys(appearOnPageTypeEnum).map(t => ({
        value: appearOnPageTypeEnum[t],
        text: appearOnPageTypeText[appearOnPageTypeEnum[t]]
      }))
    },
    linkToItems() {
      return Object.keys(linkToEnum).map(t => ({
        value: linkToEnum[t],
        text: linkToText[linkToEnum[t]]
      }))
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditHeroBanner', [
          this.heroBannerObject.org_codename
        ])
      )
    },
    linkContentClass() {
      if (this.heroBannerObject.link_contents.length > 0) {
        return 'primary600Color--text'
      } else {
        return 'disableTextColor--text'
      }
    },
    linkContentTitleTh() {
      if (this.heroBannerObject.link_contents.length > 0) {
        return this.heroBannerObject.link_contents[0].code === 'th'
          ? this.heroBannerObject.link_contents[0].title
          : this.heroBannerObject.link_contents[1].title
      } else {
        return 'Content Title will appear here.'
      }
    },
    linkContentTitleEn() {
      if (this.heroBannerObject.link_contents.length > 0) {
        return this.heroBannerObject.link_contents[0].code === 'en'
          ? this.heroBannerObject.link_contents[0].title
          : this.heroBannerObject.link_contents[1].title
      } else {
        return 'Content Title will appear here.'
      }
    },
    sectionInfo() {
      const hasSection = this.heroBannerObject.section.value !== 0
      const hasContent = this.heroBannerObject.link_contents.length > 0
      if (hasSection && hasContent) {
        const sectionName = this.heroBannerObject.section.text
        const contentName =
          this.heroBannerObject.link_contents[0].code === 'en'
            ? this.heroBannerObject.link_contents[0].title
            : this.heroBannerObject.link_contents[1].title
        return `${sectionName} > ${contentName}`
      } else {
        return ''
      }
    },
    updatedBy() {
      return this.heroBannerObject.update_by_user !== null
        ? this.heroBannerObject.update_by_user.name
        : ''
    },
    updatedAt() {
      return this.heroBannerObject.update_date !== null
        ? convertDate(this.heroBannerObject.update_date)
        : ''
    },
    ...mapState('heroBanner', [
      'errorMessage',
      'heroBannerObject',
      'imageEnErrorMessage',
      'imageThErrorMessage',
      'isDelete',
      'isExpired',
      'isImageEnError',
      'isImageThError',
      'isLoading',
      'isPublished',
      'isUnpublished',
      'orgObject'
    ])
  },
  methods: {
    closeAlert() {
      this.isSuccess = false
      this.isError = false
    },
    closePreview() {
      this.isPreview = false
    },
    isDisabledPreview() {
      if (
        !this.selectedBranch ||
        this.isBannerTypeError ||
        this.isAppearOnError ||
        this.isLinkToContentError ||
        this.isBrowserTypeError ||
        this.isUrlError ||
        this.isPublishStartDateError ||
        this.isStartTimeError ||
        this.isPublishEndDateError ||
        this.isEndTimeError ||
        this.isImageThError ||
        this.isImageEnError ||
        this.heroBannerObject.contents[0].title === null||
        this.heroBannerObject.contents[0].title === ''||
        this.heroBannerObject.contents[1].title === null ||
        this.heroBannerObject.contents[1].title === '' ||
        this.heroBannerObject.contents[0].thumbnail === '' ||
        this.heroBannerObject.contents[1].thumbnail === ''
      ) {
        return true
      }
      return false
    },
    showPreview() {
      this.isPreview = true
    },
    resetContentDialogComponent() {
      this.componentKey++
    },
    resetStartDatetimeWithCheckStatus() {
      // create mode && edit unpublished mode will allow to resetStartDatetime
      if (!this.isEditMode) {
        this.resetStartDatetime()
      } else {
        if (this.isUnpublished) {
          this.resetStartDatetime()
        }
      }
    },
    resetPublishDateTime(hasExpiry) {
      if (!hasExpiry) {
        this.resetEndDatetime()
      }
      this.resetStartDatetimeWithCheckStatus()
      this.$refs.datetimeForm.resetValidation()
    },
    resetAll() {
      this.resetSection()
      this.resetContent()
      this.resetContentTable()
      this.resetContentDialogComponent()
      this.resetWebsite()
      this.resetStartDatetime()
      this.resetEndDatetime()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
    },
    resetError() {
      this.isLinkToContentError = false
      this.isBrowserTypeError = false
      this.isUrlError = false
    },
    changeBranch() {
      this.resetAll()
      this.resetLinkTo()
      this.resetError()
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.heroBannerObject.org_codename = value[0]
      }
    },
    changeType() {
      this.resetAll()
      this.resetLinkTo()
      this.resetError()
    },
    changeLinkTo() {
      this.resetAll()
      this.resetError()
    },
    changeBrowserType() {
      this.heroBannerObject.route_value = ''
      this.resetStartDatetimeWithCheckStatus()
      this.resetEndDatetime()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
    },
    imageRules(lang, val) {
      if (this.isEditMode) {
        this.setImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setImageError({lang, isError: false, message: ''})
        return
      }
    },
    validate() {
      this.imageRules(
        this.heroBannerObject.contents[0].code,
        this.heroBannerObject.contents[0].thumbnail
      )
      this.imageRules(
        this.heroBannerObject.contents[1].code,
        this.heroBannerObject.contents[1].thumbnail
      )
      const validDatetime = this.$refs.datetimeForm.validate()
      if (
        this.$refs.form.validate() &&
        validDatetime &&
        !this.isImageThError &&
        !this.isImageEnError &&
        this.heroBannerObject.contents[0].thumbnail !== '' &&
        this.heroBannerObject.contents[1].thumbnail !== ''
      ) {
        return true
      }
      return false
    },
    async confirmDelete() {
      const isSuccess = await this.delete()
      if (isSuccess) {
        this.$router.push({
          name: 'hero-banner-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.duplicateContent = false
      this.resetState()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
      this.$router.push({
        name: 'hero-banner-list'
      })
    },
    async save() {
      if (this.validate()) {
        if (!this.isEditMode) {
          try {
            this.isSuccess = await this.create(this.heroBannerObject)
            if (this.isSuccess) {
              this.duplicateContent = false
              this.$refs.form.resetValidation()
              this.resetState()
              this.$router.push({
                name: 'hero-banner-list',
                query: {isCreateSuccess: true, snackTitle: 'created'}
              })
              return
            } else {
              this.isError = true
              this.msgError = this.errorMessage || 'Incomplete information'
            }
          } catch (e) {
            this.isError = true
            this.msgError = this.errorMessage || 'Incomplete information'
            return
          }
        } else {
          try {
            let payload = {
              id: this.$route.params.id,
              object: this.heroBannerObject
            }
            const isSuccess = await this.update(payload)
            if (isSuccess) {
              this.$router.push({
                name: 'hero-banner-list',
                query: {isCreateSuccess: true, snackTitle: 'edited'}
              })
            } else {
              this.isError = true
              this.msgError = this.errorMessage || 'Incomplete information'
            }
          } catch (e) {
            this.isError = true
            this.msgError = this.errorMessage || 'Incomplete information'
            return
          }
        }
      }
    },
    checkThaiContentExpand() {
      if (this.isThaiContentExpand) {
        this.isThaiContentExpand = false
      } else {
        this.isThaiContentExpand = true
      }
    },
    checkEngContentExpand() {
      if (this.isEngContentExpand) {
        this.isEngContentExpand = false
      } else {
        this.isEngContentExpand = true
      }
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.heroBannerObject.contents[1].title = this.heroBannerObject.contents[0].title
        this.heroBannerObject.contents[1].thumbnail = this.heroBannerObject.contents[0].thumbnail
      } else {
        this.heroBannerObject.contents[1].title = null
        this.heroBannerObject.contents[1].thumbnail = ''
      }
      this.imageRules(
        this.heroBannerObject.contents[1].code,
        this.heroBannerObject.contents[1].thumbnail
      )
    },
    startDateChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.heroBannerObject.end_date = ''
        this.heroBannerObject.start_time = ''
        this.heroBannerObject.end_time = ''
      }
    },
    startTimeChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.heroBannerObject.end_time = ''
      }
    },
    endDateChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.heroBannerObject.end_time = ''
      }
    },
    allowedDates(val) {
      return moment(this.heroBannerObject.start_date).isSameOrBefore(
        moment(val)
      )
    },
    startDateRule(val) {
      if (moment(this.heroBannerObject.end_date).isBefore(moment(val))) {
        return 'The start date must be less than the end date.'
      } else {
        return true
      }
    },
    startTimeMoreThanCurrentRule(val) {
      return this.isPublished ||
        this.isExpired ||
        this.disabledEdit ||
        this.disableEmptyBranch
        ? true
        : timeMoreThanCurrentRule(val, this.heroBannerObject.start_date)
    },
    startTimeMoreThanEndTimeRule(val) {
      return this.disableHasExpiredDate
        ? timeMoreThanStartRule(
            this.heroBannerObject.end_time,
            val,
            this.heroBannerObject.start_date,
            this.heroBannerObject.end_date,
            'The start time must be less than the end time.'
          )
        : true
    },
    endTimeRule(val) {
      if (this.disableHasExpiredDate) {
        return true
      } else {
        return timeMoreThanStartRule(
          val,
          this.heroBannerObject.start_time,
          this.heroBannerObject.start_date,
          this.heroBannerObject.end_date
        )
      }
    },
    changeUrlTh(val) {
      if (this.duplicateContent) {
        this.setImage({lang: 'en', file: val})
        this.setImageError({lang: 'en', isError: false, message: ''})
      }
    },
    removeImageTh() {
      this.heroBannerObject.contents[0].thumbnail = ''
      if (this.duplicateContent) {
        this.heroBannerObject.contents[1].thumbnail = ''
        this.imageRules(
          this.heroBannerObject.contents[1].code,
          this.heroBannerObject.contents[1].thumbnail
        )
      }
    },
    removeImageEn() {
      this.heroBannerObject.contents[1].thumbnail = ''
    },
    changeTitleTh(val) {
      this.heroBannerObject.contents[0].title = val
      if (this.duplicateContent) {
        this.heroBannerObject.contents[1].title = val
      }
    },
    changeTitleEn(val) {
      this.heroBannerObject.contents[1].title = val
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    clearLinkContent() {
      if (this.isPublished) {
        this.resetEndDatetime()
      } else {
        this.resetStartDatetime()
        this.resetEndDatetime()
      }
      this.$refs.datetimeForm.resetValidation()
      this.resetSection()
      this.resetContent()
      this.resetContentTable()
      this.isClearContentDialog = true
    },
    clickLinkToContent() {
      this.showSelectContent = true
    },
    ...mapActions('heroBanner', [
      'create',
      'delete',
      'fetchSections',
      'resetContent',
      'resetContentTable',
      'resetEndDatetime',
      'resetLinkTo',
      'resetSection',
      'resetStartDatetime',
      'resetState',
      'resetWebsite',
      'setDeleteId',
      'setDeletePopup',
      'setEditMode',
      'setHeroBannerObject',
      'setImage',
      'setImageError',
      'setOrgInfo',
      'update'
    ])
  }
}
</script>

<style scoped></style>
