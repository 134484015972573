<template>
  <div class="d-flex justify-center edit-container">
    <v-card
      t-data="hero-banner-card"
      class="border-radius-4 d-flex flex-column align-left align-self-center align-content-center justify-center"
      width="240"
      max-width="240"
    >
      <span t-data="image-remove-button" class="ma-2 pa-1 image-remove-button">
        <v-icon light>
          mdi-close
        </v-icon>
      </span>
      <v-img
        :src="src"
        height="340"
        width="240"
        max-height="340"
        max-width="240"
        aspect-ratio="1.5"
        class="preview-uploaded-image border-radius-4"
      >
      </v-img>
      <v-card-title t-data="card-title" class="text-h6 pb-6">
        <v-checkbox
          color="secondary"
          :label="label"
          class="mt-0 not-show-checkbox sub-body-regular"
          hide-details
          large
        ></v-checkbox>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'HeroBannerCard',
  props: {
    src: {type: String},
    label: {type: String}
  },
}
</script>

<style lang="scss">
.border-radius-4 {
  border-radius: 4px;
}
.not-show-checkbox > .v-input__control > .v-input__slot > .v-label {
  font-size: 14px !important;
}
</style>
